<template>
  <v-container>
    <v-row
      ><v-col cols="12">
        <h3>System Updates and Announcements</h3>
      </v-col></v-row
    >
    <v-row
      ><v-col cols="10">
        <v-btn fab to="/admin/update/edit" color="info">
          <v-icon small>add</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="2">
        <v-btn small @click="load" fab color="accent">
          <v-icon small dark>mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list three-line>
          <template v-for="item in list">
            <v-list-item :key="item.id">
              <v-list-item-content>
                <v-list-item-title
                  class="title"
                  v-html="item.title"
                ></v-list-item-title>
                <v-list-item-subtitle v-html="item.description">
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="6" md="4">
                      <v-subheader>Created: {{ created(item) }}</v-subheader>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-subheader
                        >For: {{ item.target_user.join(', ') }}</v-subheader
                      >
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn :to="'/admin/update/edit/' + item.id" icon>
                  <v-icon :color="item.active ? 'green accent-4' : 'grey'"
                    >edit</v-icon
                  >
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="item.id + 100"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    created(item) {
      if (item.createdTime) return this.$moment(item.createdTime).fromNow()
    },
    load() {
      this.$store.dispatch('getUpdates').then(list => {
        this.list = list
      })
    }
  }
}
</script>
